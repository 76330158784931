@import url('../../components//constants/colors.css');
:root {
  --primary: #fff;
}

.hero-btns .btn-size {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  border: 2px solid var(--orange);
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  /* border: 1px solid var(--primary); */
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
  border: 2px solid white;
}

@media screen and (max-width: 960px) {
  .btn {
    margin: 0px -50px;
  }
}
