@import url('../../components//constants//colors.css');
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
.hero-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

/* hero section css */

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  text-align: center;
}

.hero-container > h1 {
  margin-top: -100px;
}

.hero-container .hero-title {
  color: #fff;
  text-transform: uppercase;
  font-size: 35px;
  padding: 0px 20px;
}

.hero-container > p {
  margin-top: 18px;
  padding: 0px 20px;
}
.hero-container .hero-text {
  margin-top: 30px;
  padding: 0px 20px;
  color: #fff;
  font-size: 18px;
}
.hero-text-wrap {
  padding: 30px 0px;
  background: rgba(28, 23, 21, 0.7);
  color: #fff;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  border-top: 5px solid var(--orange);
  width: 70%;
}

.hero-btns {
  margin-top: 32px;
}

/* top section css */
.top-container {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  background: #2b2320;
  object-fit: contain;
  /* background-image: url('/images/img-8.jpg'); */
  opacity: 0.8;
}

.top-container > h1 {
  color: #fff;
  font-size: 35px;
  text-align: center;
}
.top-container > p {
  color: #fff;
  text-align: center;
}
/* help whe  section css */

.help-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-image: url('/images/img-5.jpg');
}

.help-container > h1 {
  color: #fff;
  font-size: 50px;
  padding: 50px 0px 30px 0px;
}
.help-container > p {
  color: #fff;
  margin: 0 150px;
  text-align: center;
  padding-bottom: 50px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
  .help-container > p {
    margin: 0 50px;
  }
}

/* top section css */
