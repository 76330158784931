@import url('../../components//constants//colors.css');
.navbar {
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 100%;
  text-transform: uppercase;
}

.navbar-logo {
  color: #4d4643;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
}

.nav-item {
  height: 80px;
  font-weight: bold;
}
.nav-item ul {
  display: block;
  left: 20px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
  list-style: none;
}
.headerTitleNameWeb {
  padding-left: 10px;
  padding-right: 5px;
}
.user-icon-web {
  font-size: 20px;
}

.nav-item:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
  list-style: none;
}

.nav-links {
  color: #4d4643;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.8rem;
  height: 100%;
}

.navbar-active {
  color: var(--orange);
}
.nav-links:hover {
  border-bottom: 4px solid #4d4643;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #4d4643;
}

.menu-icon {
  display: none;
}

.logo {
  margin-top: -20px;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 90vh; */
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #4d4643;
    font-size: 2rem;
  }
  .logo {
    margin-top: 2px;
    margin-left: -20px;
  }
  .nav-item ul {
    background: #242222;
  }
}
