@import url('../src/components/constants/colors.css');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  color: #343a40;
}

a {
  color: #ae9a92;
}

a:hover {
  color: var(--orange);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Arial, Helvetica, sans-serif;
  color: #343a40;
}

.required-detail {
  color: var(--orange);
}
.main_container {
  padding: 4rem;
  background: #fff;
}

.get-started {
  color: #18d26e;
  margin-top: 30px;
  text-align: center;
}

.btn-global {
  background-color: var(--orange);
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-size: 18px;
  text-transform: uppercase;
}
.btn-global-small {
  background-color: var(--orange);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-size: 15px;
  text-transform: uppercase;
}
.btn-space-bt {
  margin-bottom: 10px;
}
.btn-global:hover {
  background-color: black;
  transition: 0.3s ease-out;
  color: #fff;
  border-color: #fff;
}
.btn-global-small:hover {
  background-color: black;
  transition: 0.3s ease-out;
  color: #fff;
  border-color: #fff;
}

@media (max-width: 667px) {
  .main_container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
