/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
@import url('../../components//constants//colors.css');
#footer {
  color: #b4aca8;
  font-size: 14px;
  background: #14100f;
  margin-bottom: -1px;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: rgb(32, 30, 30);
}
#footer .footer-base {
  background: #2b2320;
}
#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 22px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  text-align: left;
}
#footer .footer-top .footer-lawyer h4 {
  text-transform: uppercase;
  text-align: left;
}
#footer .footer-top .footer-lawyer .footer-address {
  margin-bottom: 15px;
}
#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Muli', sans-serif;
  color: #b4aca8;
  padding-right: 50px;
  text-align: justify;
}

#footer .footer-top .footer-contact i {
  color: var(--orange);
  padding-right: 8px;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links .for-lawyer {
  margin-top: 20px;
}

#footer .footer-top .footer-links h4 {
  text-transform: uppercase;
  text-align: left;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: var(--orange);
  font-size: 15px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links input {
  margin-top: auto;
  min-width: auto;
  height: auto;
  padding: auto;
  font-family: 'Open Sans', sans-serif;
  border: auto;
  margin-bottom: auto;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #b4aca8;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links span {
  color: #b4aca8;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  padding-left: 10px;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: var(--orange);
}

#footer .footer-newsletter {
  font-size: 15px;
}
#footer .footer-newsletter div {
  margin-right: 50px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-newsletter p {
  color: #b4aca8;
}

#footer .footer-newsletter div {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px 0 0 4px;
  text-align: left;
}
#footer .footer-newsletter .footer-stripe {
  background-color: auto;
  border-radius: 4px;
}
#footer .footer-newsletter .footer-image {
  width: 100%;
  height: 100%;
}
#footer .footer-newsletter .errorMessage {
  color: var(--orange);
}

#footer .footer-newsletter div input[type='email'] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter div input[type='submit'] {
  position: absolute;
  top: 0;
  right: -4px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--orange);
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter div input[type='submit']:hover {
  background: var(--orange);
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #b4aca8;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #504e4d;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: var(--orange);
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  #footer .footer-top {
    padding-left: 30px;
  }
  #footer .footer-top .footer-contact p {
    padding-right: 30px;
  }
}
