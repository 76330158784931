.nav-sidebar p {
  color: white;
}
.each-detail h5 {
  color: white;
}
.checked {
  color: green;
}
.unchecked {
  color: red;
}
