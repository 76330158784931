@import url('../components/constants/colors.css');
/* login page css */
.login-icon {
  padding-right: 5px;
}
.user-icon {
  font-size: 20px;
  padding-top: 10px;
}
.user-detail-record {
  margin-bottom: 20px;
}
.ImageSt {
  margin: 30px 0px 50px 20px;
}
.headerTitleName {
  padding-left: 10px;
}
.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
}
input {
  height: 37px;
  padding: 0px 10px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  transition: all 250ms ease-in-out;
}
.input {
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-bottom: 5px;
  /* width: 80%; */
}
.input-side-two {
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-bottom: 5px;
  width: 80%;
}
.input-side-three {
  width: 20%;
}
.search-group {
  display: block;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.input-side {
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-bottom: 20px;
  width: 96%;
}
.searchForm {
  flex: none;
}
.searchForm {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  margin: 0;
}
.searchFormDocument {
  display: inline-block;
  width: auto;
  margin: 0;
}
.search-side {
  text-align: right;
  padding-right: 50px;
}
.cms-title {
  margin-bottom: 25px;
}
.submit {
  background-color: var(--orange);
  border: 0;
  padding: 7px 18px;
  margin-left: -8px;
  color: #fff;
  transition: 0.4s;
}
.clear {
  margin-left: 15px;
  text-decoration: none;
  color: grey;
  font-weight: bold;
}
.remove-clear-input {
  display: none;
}
.search-space {
  margin-bottom: 40px;
}
select {
  height: 37px;
  padding: 0px 10px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  transition: all 250ms ease-in-out;
}
textarea {
  margin-top: 6px;
  min-width: 18em;
  height: 100px;
  padding: 0px 10px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 4px;
  margin-bottom: 15px;
  transition: all 250ms ease-in-out;
}
.sign-in-box {
  margin-left: 15%;
  margin-right: 15%;
  align-content: center;
}

.header {
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  margin: 25px 0;
}
.box {
  display: flex;
  flex-direction: column;
}
.footer {
  margin-top: 3em;
}
/*end of login css */

/* Pagination */
.paginationBttns {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #2b2eff;
  color: #2b2eff;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #2b2eff;
}

.paginationActive a {
  color: white;
  background-color: #2b2eff;
}

.paginationDisabled a {
  color: grey;
  background-color: white;
  pointer-events: none;
}
.pgBtSp {
  margin-bottom: 30px;
}

/* End of pagination */

/* Editor css */

.wrapperClassName {
  margin-bottom: 50px;
}
.editorClassName {
  border: 1px solid #44504a;
}
/* .toolbarClassName {
  border: 10px solid #18d26e;
}
.toolbarStyle {
  border: 10px solid #18d26e;
} */

/* End of editor css */

/* users css */
.headerTable {
  background-color: black;
  color: white;
}
.form-users {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-users-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-group-users {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
}
.input-users {
  margin-top: 6px;
  min-width: 18em;
  height: 37px;
  padding: 0px 10px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 4px;
  margin-bottom: 15px;
  transition: all 250ms ease-in-out;
}
.sMediaColor {
  color: blue;
}

/*end of users css*/
.wrapper {
  flex: 1;
  align-items: center;
  align-content: center;
  text-align: center;
}

.textInputs {
  margin-top: 15px;
}
.textInput {
  padding: 5px 10px;
  width: '100%';
}
.errorMessage {
  color: var(--orange);
}

/*--------------------------------------------------------------
  # Home
  --------------------------------------------------------------*/
.home .first-top {
  text-align: center;
}
.home .home-icon {
  font-size: 70px;
  color: rgb(73, 65, 65);
}

.home .home-icon i {
  background-color: #b8860b;
  border-radius: 10px;
  padding: 20px;
}
.home .home-title {
  padding-top: 20px;
}
.home .home-title a {
  color: black;
  font-size: 30px;
}
.home .home-title a:hover {
  color: var(--orange);
}
.home .home-description {
  padding: 0px 15px;
}

/*--------------------------------------------------------------
  # Why choose us
  --------------------------------------------------------------*/
.choose-us {
  text-align: center;
}
.choose-us .section-title h2 {
  color: black;
  font-size: 35px;
}
.choose-us .home-title {
  padding-top: 20px;
}
.choose-us .home-description {
  padding: 0px 15px;
}
.choose-us .home-icon img {
  padding: 20px;
}
.confirm_details {
  color: black;
}
.confirm_details span {
  /* padding: 5px;
  background: blue; */
  color: blue;
  /* border-radius: 5px; */
}
.pricing-toggle {
  text-align: center;
}
.pricing-save {
  background: gray;
  color: white;
  padding: 5px 0px 10px 10px;
  margin: 0px 80px -10px 10px;
  border-radius: 10px;
  width: 45%;
}
.pac-to-space {
  padding-top: 20px;
}
.pricing-save-free {
  background: gray;
  color: white;
  padding: 5px 0px 10px 10px;
  margin: 0px 80px -10px 10px;
  border-radius: 10px;
  width: 65%;
}
.billingTitle {
  text-transform: capitalize;
}
.billing-content {
  text-align: none;
}
.billing-content p {
  text-align: justify;
}
.billing-content b {
  text-align: left;
}
.billing-content p span {
  text-align: end;
  padding-left: 20px;
  text-transform: capitalize;
}
/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact {
  margin: 10px 0px;
}
.btn-text-align {
  text-align: center;
}
.btn-style {
  padding: 9px;
  border-radius: 5px;
}
.btn-text-right {
  text-align: right;
}
.forgot-password > a {
  color: black;
}
.forgot-password > a:hover {
  color: var(--orange);
}
.contact .info-wrap {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}
.iframe-section {
  border: 0;
  width: 100%;
  height: 350px;
}

.contact .cdetail {
  padding: 20px 0px;
  text-align: center;
  background: #fff;
}

.contact .info i {
  font-size: large;
  color: var(--orange);
  width: 44px;
  height: 44px;
  background: #ffeee8;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  padding-top: 10px;
}

.contact .info:hover i {
  background: var(--orange);
  font-size: x-large;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .validate {
  display: none;
  color: var(--orange);
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}
.reset-code {
  text-align: center;
}
.reset-code div a {
  text-align: center;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: var(--orange);
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type='submit'] {
  background: var(--orange);
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type='submit']:hover {
  background: var(--orange);
}

.search-document input {
  width: calc(100% - 100px);
}
.search-document input {
  padding: 4px 8px;
  width: calc(100% - 100px);
}

.doc_bank .image {
  height: 200px;
}

.doc_bank {
  height: 80px;
  background: white;
}

/* projects css */

.project .tags {
  padding: 0;
  margin: 0 auto 50px auto;
  list-style: none;
  text-align: center;
}

.project .tag {
  outline: none;
  border: none;
  margin: 0px 5px;
  background-color: transparent;

  cursor: pointer;
  padding: 8px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #4d4643;
  transition: all 0.3s;
  border-radius: 50px;
}

.project .tag:hover {
  color: #fff;
  background: var(--orange);
}

.project .active {
  border-bottom: 1px solid #eee;
  color: #fff;
  background: var(--orange);
}
.project .container {
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.project .top-name {
  text-align: center;
  text-transform: capitalize;
}
.project .image-card {
  padding: 5px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.project .image {
  width: 100%;
}

/* document bank css */

.document .tags {
  padding: 0;
  margin: 0 auto 50px auto;
  list-style: none;
  /* text-align: center; */
}

.document .tag {
  outline: none;
  border: 1px solid var(--orange);
  margin: 0px 5px;
  background-color: transparent;

  cursor: pointer;
  padding: 8px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #4d4643;
  transition: all 0.3s;
  border-radius: 50px;
}

.document .tag:hover {
  color: #fff;
  background: var(--orange);
}

.document .active {
  color: #fff;
  background: var(--orange);
}
.document .container {
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.document .top-name {
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
}
.document .image-card {
  padding: 5px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.document .image {
  width: 100%;
}
.icon-started {
  padding-left: 5px;
  font-size: 18px;
}
.doc_main {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding: 25px;
}
.dow-buy {
  background-color: var(--orange);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-size: 18px;
  text-transform: capitalize;
}
.btn-doc-right {
  text-align: right;
}

.doc_main h4 {
  text-align: left;
}

.doc_main :hover {
  color: var(--orange);
}
.dow-buy:hover {
  background-color: black;
  transition: 0.3s ease-out;
  color: #fff;
  border-color: #fff;
}
.doc_main .doc_title {
  color: black;
  font-size: 20px;
  font-weight: 'bold';
  text-transform: uppercase;
}

.doc_main h5 span {
  font-size: 30px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
}

.doc_main h5 span sup {
  font-size: 20px;
}

.doc_main .doc_sub_main i {
  font-size: 40px;
  margin-bottom: 15px;
}
.doc_main .doc_free {
  font-size: 25px;
}

.doc_main .doc_down {
  color: black;
  text-align: justify;
}
/* assess more document */
.access-more h3 {
  font-weight: bold;
}
.access-more span {
  color: #18d26e;
}
.access-more p {
  text-align: justify;
}

.access-more-btn {
  text-align: right;
  margin-top: 20px;
}
/* terms and others */
.main-headers p {
  text-align: justify;
}
.main-headers a {
  color: blue;
}
.main-headers .list-headers {
  margin-left: 20px;
}
.main-headers .first-privacy-list {
  margin-left: 20px;
}
.main-headers .redColor {
  color: red;
}
.list-headers .first-list li {
  font-size: large;
  font-weight: bold;
}
.list-headers .first-list .service-top-space {
  margin-top: 10px;
}

.list-headers .second-list {
  margin-left: 20px;
  margin-top: 10px;
}
.list-headers .third-list {
  margin-left: 20px;
  margin-top: 10px;
}
.list-headers .second-list li {
  font-size: 16px;
  font-weight: normal;
  text-align: justify;
}

/* about css */

.about-top .content {
  margin-top: -70px;
}
.about-us .content h3 {
  font-weight: 700;
  font-size: 34px;
}

.about-us .content p {
  margin-bottom: 0;
}

.about-us .content .icon-box {
  margin-top: 25px;
}

.about-us .content .icon-box h4 {
  font-weight: 700;
  margin: 5px 0 10px 60px;
  text-transform: uppercase;
  text-align: left;
}

.about-us .content .icon-box i {
  font-size: 30px;
  float: left;
  color: #b4aca8;
}

.about-us .content .icon-box p {
  margin-left: 60px;
  text-align: justify;
}
.about-us .about-space {
  margin-top: 10px;
}

.about-us .image {
  background: url('/images/banner/side.jpg') center center no-repeat;
  background-size: cover;
  min-height: 400px;
}
#our-mission {
  background-color: #f3f1f0;
}
/* .no-gutters {
  padding: 30px 0px;
} */

.clients .clients-wrap {
  border-top: 1px solid #f1eeed;
  border-left: 1px solid #f1eeed;
}

.clients .client-logo {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #f1eeed;
  border-bottom: 1px solid #f1eeed;
  overflow: hidden;
  background: #fff;
  height: 100px;
}

.clients .client-logo img {
  transition: all 0.3s ease-in-out;
  height: 36px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.clients .client-logo:hover img {
  -webkit-filter: none;
  filter: none;
  transform: scale(1.1);
}
/*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #f3f1f0;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.pricing h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #2b2320;
}

.pricing h4 {
  font-size: 42px;
  color: var(--orange);
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: var(--orange);
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #2b2320;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .btn-buy {
  color: #9c847b;
  border: 2px solid #e9e3e1;
  display: inline-block;
  padding: 10px 40px 12px 40px;
  border-radius: 4px;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Muli', sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  color: var(--orange);
  border-color: var(--orange);
}

.pricing .featured {
  background: var(--orange);
  box-shadow: none;
}

.pricing .featured h3,
.pricing .featured h4,
.pricing .featured h4 span,
.pricing .featured ul,
.pricing .featured ul .na {
  color: #fff;
}

.pricing .featured .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .featured .btn-buy {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5);
}

.pricing .featured .btn-buy:hover {
  color: var(--orange);
  background: #fff;
  border-color: #fff;
}

.pricing .icon-tick {
  padding-right: 5px;
  font-size: 10px;
  color: #65534c;
}
.pricing .icon-tick-white {
  padding-right: 5px;
  font-size: 10px;
  color: #fff;
}

/*--------------------------------------------------------------
  # Cta Pricing
  --------------------------------------------------------------*/

.block-doc {
  color: #65534c;
  margin-bottom: 50px;
  padding-left: 20px;
  margin-top: 15px;
}
.block-doc h3 {
  margin-bottom: 15px;
  font-size: 25px;
}
.block-doc span {
  margin-left: 20px;
}
.doc-side {
  margin-top: 87px;
  padding-left: 30px;
}
.block-document-side h3 {
  margin-bottom: 20px;
}
.lawy-side {
  padding-left: 30px;
}
.small-screen {
  display: none;
}

.top-search {
  display: none;
}
.serach-display .cont-search {
  display: none;
}
.down-side {
  margin-top: 10px;
  padding-left: 30px;
}
.cont-search {
  padding-left: 20px;
}
.cont-search h3 {
  font-size: 25px;
}
.clear-input {
  text-decoration: underline;
}
.no-record {
  flex: 1;
  text-align: center;
}
.cta-pricing {
  background: #f3f1f0;
  color: #65534c;
  margin-bottom: 50px;
  padding: 20px 0px;
}

.cta-pricing h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}
.cta-pricing p {
  margin-bottom: 25px;
}

.cta-pricing .cta-btn {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 2px;
  transition: 0.4s;
  margin: 10px;
  border-radius: 4px;
  border: 2px solid var(--orange);
  color: var(--orange);
  background: #fff;
}

.cta-pricing .cta-btn:hover {
  background: var(--orange);
  color: #fff;
}

/*--------------------------------------------------------------
  # Find expert
  --------------------------------------------------------------*/
.lawyers .findExpertList {
  background-color: #b4aca8;
  border-radius: 10px;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.lawyers .findExpertList li {
  list-style: none;
}

.lawyers .detail_style p {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.787);
}
.lawyers .detail_style h4 {
  margin-top: 30px;
  color: var(--orange);
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}
.lawyers .detail_style a {
  color: blue;
}
.lawyers .detail_style .firm-specialise {
  margin-top: -10px;
  font-weight: bold;
}

.lawyers .detail_style .firm-specialise span {
  font-weight: normal;
}

.lawyers .view_connect p {
  color: white;
  background-color: var(--orange);
  margin-top: 15px;
  text-align: center;
  padding: 10px 0px;
}
.lawyers .viewDoc {
  /* text-align: center; */
  padding-bottom: 20px;
}
.lawyers .lawy-paragraph {
  margin-top: 20px;
  text-align: justify;
}
.lawyers .view_connect p:hover {
  color: var(--orange);
  background-color: white;
  margin-top: 15px;
  text-align: center;
}
.lawyers .each__view_back {
  padding-bottom: 20px;
}
.lawyers .img-responsive {
  margin: 15px 0px;
}
.lawyers-user-add {
  margin-top: 6px;
}
/*--------------------------------------------------------------
  # Our Team
  --------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
}

.team .member .social a {
  transition: color 0.3s;
  color: #2b2320;
  margin: 0 10px;
  padding-top: 8px;
  display: inline-block;
}

.team .member .social a:hover {
  color: var(--orange);
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #2b2320;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #b4aca8;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #847872;
}

.team .member:hover .social {
  opacity: 1;
}

/*s section css */
.section-bg {
  background-color: #f6f3f2;
}

.section-title {
  padding-bottom: 30px;
  text-align: center;
}

.section-title h2 {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
  padding-bottom: 0;
}

.section-title h2 strong {
  font-weight: 700;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 50px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #4d4643;
  transition: all 0.3s;
  border-radius: 50px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: var(--orange);
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #2b2320;
  text-align: left;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #65534c;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #483b36;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: var(--orange);
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-links a:hover {
  color: var(--orange);
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
  padding: 40px;
}

.portfolio-details .portfolio-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 40px;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: var(--orange) !important;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-info p {
  font-size: 15px;
  padding: 15px 0 0 0;
}

/*--------------------------------------------------------------
  # Dashboard
  --------------------------------------------------------------*/
.dash-top {
  margin: 20px 0px;
}
.dash-first-column {
  background-color: blue;
  color: white;
}
.dash-second-column {
  background-color: green;
  color: white;
}
.dash-third-column {
  background-color: red;
  color: white;
}
.dash-fourth-column {
  background-color: orange;
  color: white;
}
.dash-icon {
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dash-spaces {
  margin-top: 10px;
}
.dash-title {
  padding-top: 25px;
  padding-left: 20px;
}
.dash-title p {
  color: white;
}
.dash-column-list {
  text-align: center;
  background: black;
}
.dash-column-list p {
  color: white;
}
.dash-first-theader {
  text-align: center;
}
.billing-up {
  margin-bottom: 20px;
}
.billing-up p {
  color: black;
}

.billing-up p b {
  color: green;
}
.billing-status-activate {
  color: green;
}
.billing-status-diactivate {
  color: blue;
}
.billing-status-cancel {
  color: orange;
}
.billing-status-upgrade {
  color: gray;
}
.billing-status-terminate {
  color: red;
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services .icon-box {
  padding: 50px 20px;
  margin-top: 35px;
  margin-bottom: 25px;
  text-align: center;
  border: 1px solid #ebe6e4;
  height: 220px;
  position: relative;
  background: #faf9f8;
}

.services .icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  transition: 0.2s;
  border-radius: 50%;
  border: 6px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 72px;
  height: 72px;
  background: #90847f;
}

.services .icon i {
  color: #fff;
  font-size: 24px;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.services .title a {
  color: #343a40;
}

.services .icon-box:hover .icon {
  background: #fff;
  border: 2px solid var(--orange);
}

.services .icon-box:hover .icon i {
  color: var(--orange);
}

.services .icon-box:hover .title a {
  color: var(--orange);
}

.services .description {
  line-height: 24px;
  font-size: 14px;
}
.home-mission-title {
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  margin-top: 25px;
}
.home-control {
  margin-bottom: 25px;
}
.home-mission {
  text-align: justify;
}
.get-started-image {
  background-size: cover;
}
.get-started-content p {
  padding: 20px 50px 0px 50px;
}
.make-impact .impact-image {
  background-size: cover;
}
.make-impact .content-impact h3 {
  padding: 0px 0px 20px 80px;
  text-align: left;
}
.make-impact .content-impact p {
  padding: 0px 80px;
  text-align: justify;
}
.get-started-align {
  text-align: center;
}

/* dashboard */
.bashvoard-nav {
  margin-top: -20px;
}
.navbar-nav {
  margin-top: 35px;
}
.disabled-link {
  pointer-events: none;
}
.col-span {
  text-align: center;
}
.doc-download {
  color: blue;
}
.each-top {
  margin-top: 10px;
}
.each-detail {
  background-color: #343a40;
  color: white;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.each-p span {
  color: gray;
}
.each-desc {
  text-align: justify;
}
.imageRound {
  border-radius: 50px;
  width: 40px;
  height: 40px;
}
.logoutDropdown {
  padding-top: 10px;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  color: #333333;
  background: #dddddd;
  border-radius: 50%;
  font-size: large;
  margin-right: 25px;
}
button[type='button'] {
  border: none;
  outline: none;
}
.icon-button:hover {
  background: #cccccc;
}
.main_spinner {
  text-align: center;
  margin-bottom: 20px;
}

.badge-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.profile-side-bar {
  background-color: #343a40;
  padding: 20px;
}

.profile-side-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.flavicon-image {
  margin: 0px 10px 0px 20px;
}

.profile-side-bar li {
  padding-bottom: 20px;
}

.selected {
  color: white;
  /* margin-right: 20px; */
  padding-left: 15px;
}

.selectedProfile {
  background-color: var(--orange);
}
.selectedProfile:hover {
  background-color: var(--orange);
}

/* small screen */
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .project .container {
    grid-template-columns: repeat(3, 1fr);
  }
  .services .icon-box {
    height: 240px;
  }
  #our-mission .content h3 {
    padding-top: 3rem;
  }

  .document .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 992px) {
  .profile-side-bar li {
    display: inline;
    padding-right: 10px;
  }
  .pac-to-space {
    padding-top: 0px;
  }

  .about-us .image {
    text-align: center;
  }
  .about-us .image img {
    max-width: 80%;
  }
  .portfolio-details .portfolio-info {
    padding-top: 20px;
  }
  .project .container {
    grid-template-columns: repeat(2, 1fr);
  }
  .sign-in-box {
    margin-left: 0%;
    margin-right: 0%;
  }
  .login-top {
    margin-bottom: 20px;
  }

  .lawyers .detail_style {
    color: white;
    margin-top: 15px;
  }

  .services .icon-box {
    height: 230px;
  }
  .access-more-btn {
    text-align: center;
    margin-top: 20px;
  }
  .doc_main .doc_free {
    font-size: 20px;
  }
  .doc-side {
    display: none;
  }
  .doc-side-billing {
    display: none;
  }
  .lawy-side {
    display: none;
  }
  .large-screen {
    display: none;
  }
  .small-screen {
    display: initial;
  }
  .top-search {
    display: initial;
  }
  .down-side {
    margin-top: 30px;
    margin-left: auto;
  }
  .small-size {
    display: none;
  }
  .content-wrapper {
    display: none;
  }
  .selected {
    padding-left: 0px;
  }

  .side-bar-mobile ul li {
    margin-right: 10px;
  }
  .side-bar-mobile {
    margin-bottom: 20px;
  }
  .lawyers .detail_style h4 {
    margin-top: 1px;
  }
}

@media (max-width: 767px) {
  .contact .php-email-form {
    width: 90%;
    padding: 30px;
  }
  .user-create-account {
    margin-top: 20px;
  }
  .project .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 667px) {
  .about-us .image img {
    max-width: 100%;
  }
  .about-us .content .icon-box p {
    margin-left: 0px;
  }
  #our-mission {
    padding: 0rem 2rem;
  }
  .cta-pricing {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .document .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
